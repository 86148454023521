.module {
  position: relative;
}

.module::before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/images/Cubo/CuboPretoEspaçado.png');
  background-repeat: repeat;
  opacity: 1.5%;
}
.module-inside {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}