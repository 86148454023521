@media only screen and (max-width: 1000px) {
  .cardInfo {
    width: 100%;
    height: 3.5rem;
    border-radius: 10px;
    /* background-color: #f2e8cf; */
    display: flex;
    /* flex: 45%; */
    align-items: center;
    background-color: #cce0c7;
    justify-content: space-between;

  }

  .frenteIcon {
    height: 35px;
    border-radius: 15px;
    /* filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%) */
    /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); */
  }

  .editIcon {
    height: 15px;
    margin-left: 16px;
    cursor: pointer;
    /* font-weight: 400; */
    filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%)
      /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); */
  }

  .editIcon:hover {
    height: 18px;
    cursor: pointer;
    margin-left: 13px;
    /* font-weight: 400; */
    /* filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%) */
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  }

  .nomePetiano {
    /* font-weight: ; */
    font-weight: 400;
    /* color: white; */

  }

  .iconsBox {

    display: flex;
    justify-content: flex-end;
    height: 1.5rem;
    align-items: center;
  }

  .editInputBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .inputSizeEdition {
    width: 50%;
    display: flex;
  }
}

/* estilização para páginas com mais de 1000px e menos de 1400px de largura*/
@media only screen and (min-width: 1001px) {
  .cardInfo {
    width: 100%;
    height: 3.5rem;
    border-radius: 10px;
    /* background-color: #f2e8cf; */
    display: flex;
    /* flex: 45%; */
    align-items: center;
    background-color: #cce0c7;
    justify-content: space-between;
  }

  .frenteIcon {
    height: 35px;
    border-radius: 5px;
    /* filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%) */
    /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); */
  }

  .editIcon {
    height: 15px;
    margin-left: 16px;
    cursor: pointer;
    /* font-weight: 400; */
    filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%)
      /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); */
  }

  .editIcon:hover {
    height: 18px;
    cursor: pointer;
    margin-left: 13px;
    /* font-weight: 400; */
    /* filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%) */
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  }

  .nomePetiano {
    /* font-weight: ; */
    font-weight: 400;
    /* color: white; */

  }

  .iconsBox {

    /* background-color: red; */
    /* width: 100px; */
    display: flex;
    justify-content: flex-end;
    height: 1.5rem;
    align-items: center;
  }

  .editInputBox {
    width: 100%;

    /* background-color: RED; */
    display: flex;
    flex-wrap: wrap;
  }

  .inputSizeEdition {
    width: 50%;
    display: flex;
  }
}

/* estilização para páginas com mais de 1400px de largura*/
@media only screen and (min-width: 1400px) {

  .cardInfo {
    width: 100%;
    height: 3.5rem;
    border-radius: 10px;
    /* background-color: #f2e8cf; */
    display: flex;
    /* flex: 45%; */
    align-items: center;
    background-color: #cce0c7;
    justify-content: space-between;

  }

  .frenteIcon {
    max-height: 40px;
    border-radius: 5px;
    /* filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%) */
    /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); */
  }

  .editIcon {
    height: 15px;
    margin-left: 16px;
    cursor: pointer;
    /* font-weight: 400; */
    filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%)
      /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%); */
  }

  .editIcon:hover {
    height: 18px;
    cursor: pointer;
    margin-left: 13px;
    /* font-weight: 400; */
    /* filter: invert(56%) sepia(11%) saturate(1583%) hue-rotate(54deg) brightness(95%) contrast(87%) */
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  }

  .nomePetiano {
    /* font-weight: ; */
    font-weight: 400;
    /* color: white; */

  }

  .iconsBox {

    /* background-color: red; */
    /* width: 100px; */
    display: flex;
    justify-content: flex-end;
    height: 1.5rem;
    align-items: center;
  }

  .editInputBox {
    width: 100%;
    /* background-color: RED; */
    display: flex;

    flex-wrap: wrap;
  }

  .inputSizeEdition {
    width: 50%;
    display: flex;
  }
}