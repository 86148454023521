/* Arquivo para guardar as estilizações globais das páginas */

:root {
  /************************************/
  /* Variáveis globais de estilização */
  /************************************/

  --verde-primario: #63804c; /* Utilizada em tudo que precisa de destaque: ícones, fundos de títulos etc */
  --verde-secundario: #224a00; /* Utilizada apenas em um local do site: o footer */
  --verde-terciario: #c9e265; /* Utilizado nos botões dos cards do carrossel acima do rodapé */

  --preto-primario: #2d2d2d; /* Utilizada nos textos do site para não causar muito contraste com o branco */

  --cinza-primario: #707070; /* Usada nos textos (exceto no título) e bordas dos campos de forms dos modais (Fale Conosco e Login) */
}

body {
  max-width: 100%;
  overflow-x: hidden;
}
